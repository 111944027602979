import './slider-teaser.scss';
import { Slider } from '../../components/slider/slider';

class SliderTeaser {
    constructor (element) {
        this.$sliderTeaser = element;
        this.$sliderItems = this.$sliderTeaser.querySelectorAll('[data-slider-teaser="slide"]');
    }

    initialize () {
        if (this.$sliderItems.length > 2) {
            this.setSlider();
        }
    }

    setSlider () {
        this.$sliderTeaser = new Slider(this.$sliderTeaser, {
            speed: 500,
            indicators: true,
            slidesPerView: 'auto',
            initAttr: 'data-slider-teaser',
            counter: true,
            paginationType: 'fraction',
            fractionText: '/',
            indicatorsInControls: false
        });
    }
}

export { SliderTeaser };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $sliderTeaser = $context.querySelectorAll('[data-slider-teaser="root"]');

        for (let i = 0; i < $sliderTeaser.length; i++) {
            const $$sliderTeaser = new SliderTeaser($sliderTeaser[i]);
            $$sliderTeaser.initialize();
        }
    }
});
